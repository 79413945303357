// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-our-ads-js": () => import("./../../../src/pages/about-our-ads.js" /* webpackChunkName: "component---src-pages-about-our-ads-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quality-confidence-js": () => import("./../../../src/pages/quality-confidence.js" /* webpackChunkName: "component---src-pages-quality-confidence-js" */),
  "component---src-pages-samples-reports-js": () => import("./../../../src/pages/samples-reports.js" /* webpackChunkName: "component---src-pages-samples-reports-js" */),
  "component---src-pages-service-chemistry-testing-js": () => import("./../../../src/pages/service/chemistry-testing.js" /* webpackChunkName: "component---src-pages-service-chemistry-testing-js" */),
  "component---src-pages-service-microbiology-testing-js": () => import("./../../../src/pages/service/microbiology-testing.js" /* webpackChunkName: "component---src-pages-service-microbiology-testing-js" */),
  "component---src-pages-service-research-services-js": () => import("./../../../src/pages/service/research-services.js" /* webpackChunkName: "component---src-pages-service-research-services-js" */),
  "component---src-pages-service-technical-services-js": () => import("./../../../src/pages/service/technical-services.js" /* webpackChunkName: "component---src-pages-service-technical-services-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

